body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: #f8f9fc;
}

@media (max-width: 1000px) {
  body {
    background: #f8f9fc;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

button,
input,
textarea {
  border: none;
  background: none;
  outline: none;
}

*::-webkit-scrollbar {
  height: 3px;
  width: 7px;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
}

.overlay {
  background: rgba(241, 242, 247, 0.83);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 10% 0;
  transition: all 0.3s ease;
  z-index: 100;
}

.cursor-pointer {
  cursor: pointer;
}

.not-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 132%;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
}

.modal-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  gap: 10px;
}

a {
  color: #0458df;
  transition: all 0.3s ease;
}

a:hover {
  opacity: 0.8;
}

* {
  -webkit-tap-highlight-color: #d9d9d9;
  -webkit-tap-highlight-color: transparent;
}

@media (max-width: 400px) {
  .modal-footer {
    grid-template-columns: 1fr;
  }
}

.alert-animation {
  animation-name: redAlert;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  color: #fff !important;
}

@keyframes redAlert {
  0% {
    background: #ff2b5e;
  }

  50% {
    background: #ff9d2b;
  }

  100% {
    background: #ff2b5e;
  }
}

input,
textarea {
  font-family: "Roboto", sans-serif;
}

.ct-toast {
  z-index: 1000022 !important;
}

.card-wrapper {
  background: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
  overflow: auto;
}
